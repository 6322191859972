<template>
  <div class="account-informate">
    <h1>{{ $t("change_password_info") }}</h1>
    <b-row>
      <b-col md="6">
        <b-form-group
          class="account-inputs"
          id="password-group"
          :label="$t('form_current_password') + '*'"
          label-for="password"
        >
          <b-form-input
            id="current_password"
            v-model="form.current_password"
            type="password"
            :state="form.current_passwordState"
            aria-describedby="current_password-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="current_password-feedback">
            {{ form.current_passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="password-group"
          :label="$t('form_password') + '*'"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="form.password"
            type="password"
            :state="form.passwordState"
            aria-describedby="password-feedback"
          ></b-form-input>
          <client-only>
            <password-meter :password="form.password" />
          </client-only>
          <b-form-invalid-feedback id="password-feedback">
            {{ form.passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="password_confirm-group"
          :label="$t('form_password_confirmation') + '*'"
          label-for="password_confirm"
        >
          <b-form-input
            id="password_confirm"
            v-model="form.password_confirm"
            type="password"
            :state="form.password_confirmState"
            aria-describedby="password_confirm-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="password_confirm-feedback">
            {{ form.password_confirmError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="form-footer">
      <b-button
        @click="submit"
        variant="info"
        class="text-white form-submit-btn"
        >Opslaan</b-button
      >
    </div>
  </div>
</template>

<script>
//import passwordMeter from "vue-simple-password-meter";
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

export default {
  name: "AccountPassword",
  components: {
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
  },
  data() {
    return {
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      form: {
        email: "",
        emailState: null,
        emailError: "",
        current_password: "",
        current_passwordState: null,
        current_passwordError: "",
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
  },
  watch: {
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
  },
  methods: {
    async submit() {
      let formValid = true;
      if (this.form.current_passwordState != true) {
        this.validateCurrentPassword();
        if (this.current_passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.password_confirmState != true) {
        this.validateConfirmationPassword();
        if (this.form.password_confirmState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        const retval = await this.$store.dispatch("user/changePassword", {
          currentPassword: this.form.current_password,
          newPassword: this.form.password,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account_modify"),
            text: this.$t("account_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.form.password = "";
        } else {
          const msg = {
            type: "failure",
            title: this.$t("account_modify"),
            text: this.$t("account_not_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      }
    },
    validatePassword() {
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_to_short");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (this.form.password_confirm.length < 8) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_to_short");
      } else if (this.form.password != this.form.password_confirm) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.form.password_confirmState = true;
      }
    },

    validateCurrentPassword() {
      if (this.form.current_password == "") {
        this.form.current_passwordState = false;
        this.form.current_passwordError = this.$t("password_is_required_field");
      } else {
        this.form.current_passwordState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.account-informate {
  h1 {
    font-weight: 300;
    letter-spacing: 0.012em;
    margin-bottom: 35px;

    @include media("screen", ">=tablet") {
      font-size: 38px;
    }
  }
}

.account-form {
  h2 {
    font-weight: 400;
    letter-spacing: 0.012em;
    margin-bottom: 26px;
    border-bottom: 1px solid $gray-500;
    padding-bottom: 10px;

    @include media("screen", ">=tablet") {
      font-size: 24px;
    }
  }
}

.risk-message {
  background-color: rgba($gray-300, 0.6);
  display: block;
  padding: 1px 12px 7px;
  font-weight: 400;
  font-size: 14px;
  font-weight: 700;

  @include media("screen", ">=tablet") {
    padding: 1px 18px 7px;
    font-size: 18px;
    max-width: 80%;
  }

  &.risky {
    background-color: rgba($red, 0.6);
    color: $white;
  }

  &.guessable {
    background-color: rgba($yellow, 0.6);
  }

  &.weak {
    background-color: rgba($green, 0.4);
    color: $white;
  }

  &.safe {
    background-color: rgba($green, 0.6);
    color: $white;
  }

  &.secure {
    background-color: rgba($green, 0.8);
    color: $white;
  }
}
</style>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.radio-custom {
  padding-top: 20px;

  .custom-control-inline {
    display: block;
    margin-bottom: 20px;
  }

  .custom-control-label {
    padding-top: 2px;
    padding-left: 12px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.015em;

    @include media("screen", ">=tablet") {
      font-size: 16px;
      padding-left: 20px;
      padding-top: 0;
    }

    &:before {
      border-color: $black;
      border-radius: 6px;
      width: 16px;
      height: 16px;
      top: 5px;

      @include media("screen", ">=tablet") {
        width: 21px;
        height: 21px;
      }
    }
  }

  .custom-control-input {
    &:focus {
      outline: none;

      &:before {
        outline: none;
      }
    }
  }

  .custom-radio .custom-control-input:checked {
    ~ .custom-control-label {
      &:before {
        background-color: $green;
        border-color: $green;
      }

      &:after {
        background: none;
        border-top: 2px solid $white;
        border-left: 2px solid $white;
        transform: rotate(-135deg);
        width: 6px;
        height: 10px;
        left: -10px;
        top: 6px;

        @include media("screen", ">=tablet") {
          width: 8px;
          height: 14px;
          border-width: 3px;
          left: -8px;
          top: 7px;
        }
      }
    }
  }
}

.bv-no-focus-ring {
  &:focus {
    outline: none;
  }
}

.form-footer {
  padding-top: 3px;

  button {
    font-size: 16px;
    border-radius: 6px !important;
    padding: 2px 32px;

    @include media("screen", ">=tablet") {
      font-size: 18px;
    }
  }
}
.account-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  font-size: 14px;

  @include media("screen", ">=tablet") {
    padding-right: 14px;
    font-size: 16px;
    display: block;
  }

  .short-form & {
    margin-bottom: 20px;

    input {
      @include media("screen", ">=tablet") {
        max-width: 332px;
      }
    }
  }

  .first & {
    @include media("screen", ">=tablet") {
      padding-right: 0;
    }
  }

  label {
    min-width: 110px;
    width: auto;
    font-weight: 400;
    padding-right: 2px;

    .second &,
    .third & {
      @include media("screen", ">=tablet") {
        width: 88px;
        min-width: 88px;
      }
    }
  }

  > div {
    width: 100%;
  }

  input {
    border-color: #7f7f7f;
    border-radius: 6px;
  }

  .account-radios label {
    width: 100%;
  }
}
</style>
