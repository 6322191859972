<template>
  <div>Reviews</div>
</template>

<script>
export default {
  name: "AccountReviews",
};
</script>
<style scoped>
</style>