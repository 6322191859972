<template>
  <div>
    <div>
      <div class="account-address-book">
        <h1>{{ $t("newsletter_subscriptions") }}</h1>
        <div class="address-block">
          <h2>{{ $t("newsletter_subscription_option") }}</h2>
          <b-row>
            <b-col sm="12">
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                label-for="checkbox1"
                v-model="is_subscribed"
                >{{ $t("newsletter_subscription_selected") }}</b-form-checkbox
              >
            </b-col>
          </b-row>
        </div>

        <div class="form-footer">
          <b-button
            @click="save"
            variant="info"
            class="text-white form-submit-btn"
            >{{ $t("save") }}</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "NewsletterSubscription",
  components: {},
  mounted() {
    Logger.debug("user", "NewsletterSubscription", this.user.is_subscribed)();
    this.is_subscribed = this.user.is_subscribed;
    Logger.debug(
      "this.is_subscribed",
      "NewsletterSubscription",
      this.is_subscribed
    )();
  },
  data() {
    return {
      is_subscribed: false,
    };
  },
  computed: {
    getFilter(address, sdf) {
      console.log(address, sdf);
      return [1, 2, 3, 4, 5, 6];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  methods: {
    async save() {
      const retval = await this.$store.dispatch("user/updateUserNewsletter", {
        is_subscribed: this.is_subscribed,
      });
      if (retval == true) {
        const msg = {
          type: "success",
          title: this.$t("newsletter_subscriptions"),
          text: this.is_subscribed
            ? this.$t("newsletter_subscription_success")
            : this.$t("newsletter_unsubscription_success"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.account-address-book {
  h1,
  h2 {
    font-weight: 300;
    letter-spacing: 0.012em;
    margin-bottom: 35px;

    @include media("screen", ">=tablet") {
      font-size: 38px;
    }
  }

  h2 {
    margin-bottom: 26px;
    border-bottom: 1px solid #000000;
    padding-bottom: 18px;
    @include media("screen", ">=tablet") {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: -0.015em;

    @include media("screen", ">=tablet") {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  address {
    span {
      display: block;
    }

    a {
      color: $light-blue;
      margin-left: 4px;
    }
  }

  .link {
    color: $light-blue;
    font-size: 17px;
    font-weight: 400;
  }
}

.address-block {
  padding-bottom: 28px;
}

.adjust {
  a {
    color: $light-blue;
  }
}

.remove {
  position: relative;
  margin-left: 8px;
  padding-left: 12px;

  &:before {
    content: "|";
    position: absolute;
    left: 0;
    top: 3px;
  }

  a {
    color: $red;
  }
}

.select-group {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.02em;

  @include media("screen", ">=tablet") {
    font-size: 20px;
  }

  span {
    padding-top: 4px;
    padding-left: 10px;

    @include media("screen", ">=tablet") {
      padding-top: 0;
    }
  }
}

.select-wrap {
  position: relative;
  margin-left: 10px;

  @include media("screen", ">=tablet") {
    margin-left: 32px;
  }

  &:before {
    content: "";
    background: $white;
    position: absolute;
    border-radius: 6px;
    top: 2px;
    bottom: 2px;
    right: 2px;
    width: 30px;
    z-index: 2;
  }

  &:after {
    content: "";
    border-top: 2px solid $black;
    border-left: 2px solid $black;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(-135deg);
    width: 13px;
    height: 13px;
    margin-top: -2px;
    z-index: 3;
  }
}

.select {
  position: relative;
  width: 72px;
  font-size: 16px;
  border-radius: 6px;
  border-color: $black;
  line-height: 1;
  height: 36px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 400;

  @include media("screen", ">=tablet") {
    font-size: 20px;
  }
}

.form-submit-btn {
  letter-spacing: 0.02em;

  @include media("screen", ">=tablet") {
    min-width: 230px;
  }
}
</style>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.address-table-wrap {
  padding-top: 8px;
  padding-bottom: 2px;

  .address-table {
    line-height: 1.15;

    @include media("screen", ">=tablet") {
      padding-top: 11px;
    }

    .table {
      thead th {
        border-bottom: 1px solid $black;
        border-top: none;

        &:nth-child(3) {
          min-width: 150px;
        }
      }

      td {
        border-color: $black;
        padding-top: 23px;
        padding-bottom: 23px;
        //min-width: 80px;

        @include media("screen", ">=tablet") {
          //min-width: 110px;
        }

        &:last-child {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
