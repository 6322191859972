<template>
  <BaseLayout v-slot="{}">
    <Breadcrumbs />
    <b-container class="account">
      <b-tabs pills card vertical v-model="tabIndex">
        <b-tab :title="$t('my_account')"><AccountMyAccount /> </b-tab>
        <b-tab :title="$t('my_orders')"
          ><b-card-text><AccountOrders /></b-card-text
        ></b-tab>
        <b-tab :title="$t('my_wishlist')"
          ><b-card-text><AccountWishlist /></b-card-text
        ></b-tab>
        <b-tab :title="$t('address_book')">
          <AccountAddressBook />
        </b-tab>
        <b-tab :title="$t('account_information')">
          <AccountInformation />
        </b-tab>
        <b-tab :title="$t('change_email')">
          <AccountEmail />
        </b-tab>
        <b-tab :title="$t('change_password')">
          <AccountPassword />
        </b-tab>
        <b-tab :title="$t('my_product_reviews')">
          <b-card-text><AccountReviews /></b-card-text>
        </b-tab>
        <b-tab :title="$t('newsletter_subscriptions')">
          <NewsletterSubscription />
        </b-tab>
      </b-tabs>
    </b-container>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import AccountInformation from "@/esf_lommel_dt1_europe/core/components//AccountInformation";
import AccountEmail from "@/esf_lommel_dt1_europe/core/components//AccountEmail";
import AccountPassword from "@/esf_lommel_dt1_europe/core/components//AccountPassword";
import AccountMyAccount from "@/esf_lommel_dt1_europe/core/components//AccountMyAccount";
import AccountAddressBook from "@/esf_lommel_dt1_europe/core/components//AccountAddressBook";
import NewsletterSubscription from "@/esf_lommel_dt1_europe/core/components//NewsletterSubscription";
import AccountWishlist from "@/esf_lommel_dt1_europe/core/components//AccountWishlist";
import AccountOrders from "@/esf_lommel_dt1_europe/core/components//AccountOrders";
import AccountReviews from "@/esf_lommel_dt1_europe/core/components//AccountReviews";

//import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "Account",
  components: {
    BaseLayout,
    Breadcrumbs,
    AccountInformation,
    AccountEmail,
    AccountPassword,
    AccountAddressBook,
    AccountMyAccount,
    NewsletterSubscription,
    AccountWishlist,
    AccountOrders,
    AccountReviews,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
  },
  watch: {
    /*
    isLoggedIn(newVal, oldVal) {
      if (this.$store.getters["user/getIsLoggedIn"] == false) {
        this.$router.push({ name: "login" });
      }
    },
    */
  },
  mounted() {
    const bcrumb = { current: this.$t("account"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    /*
    if (this.$store.getters["user/getIsLoggedIn"] == false) {
      this.$router.push({ name: "login" });
    }
    */
  },
  data() {
    return {
      statusAddForm: true,
      thanksActive: false,
      form: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        checkbox2: false,
      },
    };
  },
  methods: {
    formSubmit() {
      console.log("form Submitted");
      this.thanksActive = true;
    },
    formSubmitValidate() {
      console.log("form validate");
    },
  },
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.account {
  .card-header {
    font-size: 14px;
    background: #f5f5f5;
    padding: 15px 0;
    @include media("screen", ">=tablet") {
      font-size: 19px;
      background: #f5f5f5;
      padding: 15px 0;
    }
  }

  .tabs {
    .col-auto {
      width: 33%;
      padding-right: 1%;

      @include media("screen", ">=tablet") {
        width: 28%;
        padding-right: 3%;
      }

      @include media("screen", ">=desktop") {
        width: 23%;
        height: fit-content;
      }
    }
  }
  .tab-content {
    max-width: 67%;

    @include media("screen", ">=tablet") {
      max-width: 72%;
    }

    @include media("screen", ">=desktop") {
      max-width: 77%;
    }
  }

  .nav-pills {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 16px;

    @include media("screen", ">=tablet") {
      padding-top: 20px;
    }

    li {
      margin-bottom: 10px;
      width: 100%;
      &:nth-child(3),
      &:nth-child(7) {
        border-bottom: 1px solid $gray-500;
        padding-bottom: 18px;
        margin-bottom: 14px;
      }
    }

    .nav-link {
      font-weight: 400;
      font-size: 16px;

      &.active {
        letter-spacing: -0.015em;
        position: relative;
        background: none;
        color: $black;
        font-weight: 400;
        font-size: 16px;

        &:before {
          content: "";
          background-color: $red;
          width: 3px;
          height: 16px;
          position: absolute;
          top: 4px;
          left: -18px;
          font-size: 16px;

          @include media("screen", ">=tablet") {
            top: 0px;
            height: 26px;
          }
        }
      }

      &:hover {
        color: $black !important;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.015em;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
</style>
