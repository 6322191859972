<template>
  <div class="product-card--holder">
    <a
      href="#"
      class="lnr lnr-cross add-to-wishlist"
      @click.prevent="removeItem(product.id)"
    ></a>
    <div class="product-card">
      <input type="hidden" value="1" name="quantity" />
      <b-link :to="$link(`/${product.product.url_key}`)">
        <div>
          <VueLazyBackgroundImage
            :image-source="product.product.thumbnail.url"
            image-class="product-card--img-top"
            :errorImage="logoImg"
            :loadingImage="logoImg"
            style="background-size: contain"
          >
            <div class="actionbuttons">
              <span class="sale-txt" v-if="onSale == true">sale</span>
              <span class="sale-txt" v-if="onNew == true">new</span>
            </div>
          </VueLazyBackgroundImage>
        </div>
        <div class="product-card--product-name">{{ product.product.name }}</div>
        <div class="product-card--sku">
          {{ $t("sku") }}: {{ product.product.sku }}
        </div>
      </b-link>
      <!-- <ul
        v-for="attr of product.configurable_options"
        :key="attr.id"
        class="available-sizes-list d-none d-md-flex flex-wrap justify-content-center list-unstyled p-0"
      >
        <li v-for="opt of attr.values" :key="opt.value_index">
          {{ opt.label }}
        </li>
      </ul> -->
      <!-- <Rating
        :stars="product.rating_summary"
        :noOfRatings="product.review_count"
      /> -->

      <span v-if="isLoggedIn == true" class="d-block product-card--price"
        >&euro;{{
          product.product.price_range.minimum_price.final_price.value.toFixed(2)
        }}</span
      >
      <button
        v-on:click="addToCart(product.id)"
        v-bind:disabled="btnDisable"
        class="btn btn-info product-parameter-submit-btn"
      >
        <i class="lnr lnr-cart"></i>{{ $t("add_to_cart") }}
      </button>
    </div>
  </div>
</template>

<script>
//import Rating from "@/esf_lommel_dt1_europe/core/components//Rating";
import { Logger } from "@storefront/core/lib/logger";
import VueLazyBackgroundImage from "@/esf_lommel_dt1_europe/core/components//core/VueLazyBackgroundImage";
import logoImage from "@/esf_lommel_dt1_europe/assets/logo.png";

export default {
  name: "ProductCardWishlist",
  props: {
    product: {
      type: Object,
      require: true,
    },
  },
  components: {
    //Rating,
    VueLazyBackgroundImage,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },

    onSale() {
      return false;
      // const today = new Date();
      // if (this.product.special_from_date != null) {
      //   if (new Date(this.product.special_from_date) <= today) {
      //     if (new Date(this.product.special_to_date) >= today) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    onNew() {
      return false;
      // const today = new Date();
      // if (this.product.new_from_date != null) {
      //   if (new Date(this.product.new_from_date) <= today) {
      //     if (this.product.new_to_date != null) {
      //       if (new Date(this.product.new_to_date) >= today) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     } else {
      //       return true;
      //     }
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    btnDisable() {
      if (this.isLoggedIn == true) {
        if (this.product.product.__typename == "ConfigurableProduct") {
          const retval = this.$store.getters["product/getCurrentChildSku"];
          if (retval != null) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
    async addToCart(id) {
      Logger.debug("product", "addToCart", this.product)();
      const item =
        '{data: {sku: "' +
        this.product.product.sku +
        '", quantity:' +
        1 +
        "}}  ";
      let retval = await this.$store.dispatch("cart/addToCart", {
        type: "SimpleProduct",
        item: item,
      });
      Logger.debug("retval", "addToCart", retval)();
      if (retval == false) {
        const msg = {
          type: "danger",
          title: this.$t("shopping_basket"),
          text: this.$t("not_add_product"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      } else {
        this.removeItem(id);
        this.$bvModal.show("modal-1");
      }
    },
  },
  data() {
    return {
      logoImg: logoImage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.product-card--img-top {
  background-size: contain;
}
.add-to-wishlist {
  position: absolute;
  right: 25px;
  top: 3px;
  font-size: 21px;
  z-index: 9;
  width: 22px;
  height: 33px;
  margin-top: 2rem;

  @include media("screen", ">=tablet") {
    right: 30px;
    top: 9px;
  }

  &:hover {
    ~ .product-card {
      color: #000 !important;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
    }
  }

  .heart {
    position: absolute;
    top: -2px;
    right: 0;
  }
}

.product-card {
  padding: 10px 18px;
  display: block;
  height: 100%;
  margin-top: 2rem;

  @include media("screen", ">=tablet") {
    padding: 10px 17px;
  }

  &:hover {
    color: #000 !important;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
  }

  &--holder {
    display: block;
    width: 100%;
    height: 100%;
  }

  &--img-top {
    width: 100%;
    height: 185px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 61px;
    position: relative;
    margin-top: 3rem;

    @include media("screen", ">=400px") {
      background-size: auto 100%;
    }

    @include media("screen", ">=tablet") {
      margin-bottom: 65px;
    }
  }

  &--product-name {
    display: flex;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;

    margin-bottom: 6px;
    overflow: auto;
    align-items: stretch;

    @include media("screen", ">=tablet") {
      margin-bottom: 12px;
    }
  }

  &--sku {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 6px;
    @include media("screen", ">=tablet") {
      height: 40px;
      margin-bottom: 12px;
    }
  }

  &--price {
    font-size: 16px;
    font-weight: 500;
  }

  &--sale-comment {
    font-size: 15px;
    font-weight: normal;
    color: #ff8000;
  }

  .actionbuttons {
    position: absolute;
    top: calc(100% - 28px);
    left: 3px;

    @include media("screen", ">=tablet") {
      top: calc(100% + 30px);
      left: 0;
    }
    .sale-txt {
      background: #ff8000;
      color: white;
      font-size: 14px;
      padding: 1px 12px;
    }
  }
}

.available-sizes-list {
  margin: 0 -0.7rem;
  @include media("screen", ">=widescreen") {
    margin: 0 -0.7rem 1.1rem -0.3rem;
  }
  li {
    background: #008a3e;
    color: white;
    min-width: 45px;
    height: 25px;
    margin: 0.45rem 0.7rem;
    text-align: center;
    padding: 4px 5px;
    line-height: 1;
  }
}
</style>
