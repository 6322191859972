<template>
  <div>
    <div class="address-block">
      <h1>{{ $t("my_orders") }}</h1>
      <div class="address-table-wrap">
        <h3 v-if="addressList && addressList.length === 0">
          {{ $t("no_orders_available") }}
        </h3>
        <div v-if="addressList && addressList.length">
          <b-table
            class="address-table"
            responsive
            :items="addressList"
            :fields="fields"
          >
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountOrders",
  data() {
    return {
      selectedOption: 10,
      options: [10, 20, 30],
      fields: [
        {
          key: "order_number",
          label: this.$t("order_id"),
        },
        {
          key: "order_date",
          label: this.$t("order_date"),
        },
        {
          key: "price",
          label: this.$t("grand_total"),
        },
        {
          key: "status",
          label: this.$t("status"),
        },
      ],
    };
  },
  computed: {
    addressList() {
      const orders = this.$store.getters["user/getMyOrders"].map((order) => {
        return {
          order_number: order.number,
          order_date: order.order_date,
          price: this.formatCurrency(order.total.base_grand_total.value),
          status: this.$t(order.status),
        };
      });
      return orders;
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("my_orders"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  methods: {
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, true);
    },
  },
};
</script>
<style lang="scss"  scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

h1,
h2 {
  font-weight: 300;
  letter-spacing: 0.012em;
  margin-bottom: 35px;

  @include media("screen", ">=tablet") {
    font-size: 38px;
  }
}

h2 {
  margin-bottom: 26px;
  border-bottom: 1px solid #000000;
  padding-bottom: 12px;
  @include media("screen", ">=tablet") {
    font-size: 22px;
  }
}

h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -0.015em;

  @include media("screen", ">=tablet") {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
</style>
