<template>
  <div class="account-address-book">
    <h1 v-if="editAddressId > 0">{{ $t("edit_address") }}</h1>
    <h1 v-if="editAddressId == -1">{{ $t("new_address") }}</h1>
    <div class="address-block">
      <b-row>
        <b-col md="6">
          <h2>{{ $t("contact_information") }}</h2>

          <b-form-group
            class="account-inputs"
            id="first-name-group"
            :label="$t('form_first_name') + '*'"
            label-for="first_name"
          >
            <b-form-input
              id="first_name"
              v-model="form.firstname"
              type="text"
              v-on:blur="validateFirstname()"
              :state="form.firstnameState"
              aria-describedby="firstname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="firstname-feedback">
              {{ form.firstnameError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="last-name-group"
            :label="$t('form_last_name') + '*'"
            label-for="last_name"
          >
            <b-form-input
              id="last_name"
              v-model="form.lastname"
              type="text"
              v-on:blur="validateLastname()"
              :state="form.lastnameState"
              aria-describedby="lastname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="lastname-feedback">
              {{ form.lastnameError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-checkbox
            id="checkbox-1"
            v-model="isCompany"
            name="checkbox-1"
            >{{ $t("form_are_you_a_company") }}</b-form-checkbox
          >
          <div v-if="isCompany">
            <b-form-group
              class="account-inputs"
              id="company-group"
              :label="$t('form_company_name') + '*'"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="form.company"
                type="text"
                v-on:blur="validateCompany()"
                :state="form.companyState"
                aria-describedby="company-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="company-feedback">
                {{ form.companyError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="account-inputs"
              id="taxvat-group"
              :label="$t('form_vat_number')"
              label-for="taxvat"
            >
              <b-form-input
                id="taxvat"
                v-model="form.taxvat"
                type="text"
                :state="form.taxvatState"
                aria-describedby="taxvat-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="taxvat-feedback">
                {{ form.taxvatError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <b-form-group
            class="account-inputs"
            id="telephone-group"
            :label="$t('form_phone') + '*'"
            label-for="telephone"
          >
            <b-form-input
              id="telephone"
              v-model="form.address.telephone"
              type="text"
              v-on:blur="validatePhone()"
              :state="form.address.telephoneState"
              aria-describedby="address.telephone-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.telephone-feedback">
              {{ form.address.telephoneError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <h2>{{ $t("form_street") }}</h2>

          <b-form-group
            v-if="showFullAddress"
            class="account-inputs"
            id="street-group"
            :label="$t('form_street') + '*'"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="form.address.streetDisplay"
              type="text"
              v-on:blur="validateStreet()"
              :state="form.address.streetDisplayState"
              aria-describedby="address.streetDisplay-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.streetDisplay-feedback">
              {{ form.address.streetDisplayError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="country-group"
            :label="$t('form_country') + '*'"
            label-for="country"
          >
            <b-form-select
              id="country"
              v-model="form.address.country_code"
              :options="countries"
              class="select"
              v-on:change="validateVAT()"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            v-if="!showFullAddress"
            class="account-inputs"
            id="postcode-group"
            :label="$t('form_postal_code') + '*'"
            label-for="postcode"
          >
            <b-form-input
              id="postcode"
              v-model="form.address.postcode"
              type="text"
              :state="form.address.postcodeState"
              aria-describedby="address.postcode-feedback"
              v-on:blur="validatePostcode()"
            ></b-form-input>
            <b-form-invalid-feedback id="address.postcode-feedback">
              {{ form.address.postcodeError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-row v-if="!showFullAddress">
            <b-col sm="6" class="first">
              <b-form-group
                class="account-inputs"
                id="house_number-group"
                :label="$t('form_house_number') + '*'"
                label-for="house_number"
              >
                <b-form-input
                  id="house_number"
                  v-model="form.address.house_number"
                  type="text"
                  v-on:blur="validateHouseNumber()"
                  :state="form.address.house_numberState"
                  aria-describedby="address.house_number-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="address.house_number-feedback">
                  {{ form.address.house_numberError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6" class="third">
              <b-form-group
                class="account-inputs"
                id="addition-group"
                :label="$t('form_addition')"
                label-for="addition"
              >
                <b-form-input
                  id="addition"
                  v-model="form.address.addition"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            v-if="showFullAddress"
            class="account-inputs"
            id="postcode-group1"
            :label="$t('form_postal_code') + '*'"
            label-for="postcode1"
          >
            <b-form-input
              id="postcode1"
              v-model="form.address.postcode"
              type="text"
              v-on:blur="validatePostcode()"
              :state="form.address.postcodeState"
              aria-describedby="address.postcode1-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.postcode1-feedback">
              {{ form.address.postcodeError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            v-if="showFullAddress"
            class="account-inputs"
            id="city-group"
            :label="$t('form_city') + '*'"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="form.address.city"
              type="text"
              v-on:blur="validateCity()"
              :state="form.address.cityState"
              aria-describedby="address.city-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.city-feedback">
              {{ form.address.cityError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="foundAddress != '' && showFullAddress == false"
            class="account-inputs"
            id="add-group"
            label=" "
            label-for="add"
          >
            <div id="add">
              {{ foundAddress }}
            </div>
          </b-form-group>
          <b-form-group
            v-if="foundAddresses.length > 0 && showFullAddress == false"
            class="account-inputs"
            id="addm-group"
            label=" "
            label-for="addm"
          >
            <div id="addm">
              <b-form-radio
                style="width: 300px"
                class="account-radios"
                v-model="selectedAddress"
                v-for="(item, index) in foundAddresses"
                :key="index"
                name="some-radios"
                :value="index"
                >{{ item.text }}</b-form-radio
              >
            </div>
          </b-form-group>
          <b-form-checkbox
            v-if="showManual"
            id="manual"
            v-model="showFullAddress"
            name="manual"
            >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
          >
          <b-form-checkbox
            id="default_billing"
            v-model="form.address.default_billing"
            name="default_billing"
            >{{ $t("form_default_billing") }}</b-form-checkbox
          >
          <b-form-checkbox
            id="default_shipping"
            v-model="form.address.default_shipping"
            name="default_shipping"
            >{{ $t("form_default_shipping") }}</b-form-checkbox
          >

          <!--
          <b-form-checkbox
            id="checkbox-2"
            name="checkbox-2"
            label-for="checkbox2"
            v-model="form.address.default_billing"
            >{{ $t("form_address_also_invoice_address") }}</b-form-checkbox
          >
          <b-form-checkbox
            id="checkbox-3"
            name="checkbox-3"
            label-for="checkbox3"
            v-model="form.is_subscribed"
            >{{ $t("form_newsletter_subscription") }}</b-form-checkbox
          > -->
        </b-col>
      </b-row>
    </div>
    <div class="form-footer">
      <b-button @click="cancel" class="form-submit-btn">{{
        $t("cancel")
      }}</b-button>
      &nbsp;
      <b-button
        @click="submit"
        variant="info"
        class="text-white form-submit-btn"
        >{{ $t("save") }}</b-button
      >
    </div>
  </div>
</template>
<script>
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";
import {
  validatePostcode,
  validateVatNumber,
} from "@storefront/core/data-resolver/user";

export default {
  name: "AccountEditAddress",
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
  },
  mounted() {
    if (this.editAddressId != null) {
      const address = this.$store.getters["user/getAddressByID"](
        this.editAddressId
      );
      if (address != null && address.company != null) {
        this.isCompany = true;
      } else {
        this.isCompany = false;
      }
      if (
        config.postcode_validation.enabled == false ||
        this.editAddressId != -1
      ) {
        this.manual = true;
        this.showManual = false;
      }
      if (address) {
        this.form.company = address.company;
        this.form.address.country_code = address.country_code;
        this.form.firstname = address.firstname;
        this.form.lastname = address.lastname;
        this.form.address.streetDisplay = address.street[0];
        this.form.address.postcode = address.postcode;
        this.form.address.city = address.city;
        this.form.address.telephone = address.telephone;
        this.form.address.default_billing =
          this.editAddressId == -3 ? false : address.default_billing;
        this.form.address.default_shipping =
          this.editAddressId == -2 ? false : address.default_shipping;
      } else {
        this.company = "";
        this.form.address.country_code = "";
        this.form.firstname = "";
        this.form.lastname = "";
        this.form.address.streetDisplay = "";
        this.form.address.postcode = "";
        this.form.address.city = "";
        this.form.address.telephone = "";
        this.form.address.default_billing =
          this.editAddressId == -3 ? false : true;
        this.form.address.default_shipping =
          this.editAddressId == -2 ? false : true;
      }
      Logger.debug("Form", "AccountEditAddress", this.form)();
      if (address && address.vat_id != null) {
        this.form.taxvat = address.vat_id;
      } else {
        this.form.taxvat = "";
      }
    }
    if (this.editAddressId != -1) {
      const bcrumb = { current: this.$t("edit_address"), routes: [] };
      this.$store.commit("breadcrumbs/set", bcrumb);
    } else {
      const bcrumb = { current: this.$t("new_address"), routes: [] };
      this.$store.commit("breadcrumbs/set", bcrumb);
      if (typeof config.customers.defaultCountry != "undefined") {
        this.form.address.country_code = config.customers.defaultCountry;
      }
    }
  },
  data() {
    return {
      isCompany: true,
      thanksActive: false,
      showFullAddress: true,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,

      form: {
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,

        address: {
          id: null,
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        checkbox2: false,
        manual: false,
      },
    };
  },
  methods: {
    async submit() {
      let formValid = true;
      if (this.isCompany == true) {
        if (this.form.companyState != true) {
          this.validateCompany();
          if (this.form.companyState != true) {
            formValid = false;
          }
        }
        /* Logger.debug("formSubmit", "company", formValid)();
        if (this.form.coc_numberState != true) {
          this.validateCOCNumber();
          if (this.coc_numberState != true) {
            formValid = false;
          }
        }
        Logger.debug("formSubmit", "coc_number", formValid)();
        if (this.form.taxvatState != true) {
          this.validateVAT();
          if (this.taxvatState != true) {
            formValid = false;
          }
        }
        Logger.debug("formSubmit", "taxvat", formValid)(); */
      }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        Logger.debug("formSubmit", "fistname1", this.firstnameState)();
        if (this.form.firstnameState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "fistname", formValid)();
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.form.lastnameState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "lastname", formValid)();
      if (this.form.address.postcodeState != true) {
        this.validatePostcode();
        if (this.form.address.postcodeState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "postcode", formValid)();
      if (this.form.address.streetDisplayState != true) {
        this.validateStreet();
        if (this.form.address.streetDisplayState != true) {
          formValid = false;
          this.showFullAddress = true;
        }
      }
      Logger.debug("formSubmit", "street", formValid)();
      if (this.form.address.telephoneState != true) {
        this.validatePhone();
        if (this.form.address.telephoneState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "telephone", formValid)();
      if (this.form.address.cityState != true) {
        this.validateCity();
        if (this.form.address.cityState != true) {
          formValid = false;
        }
      }
      if (this.isCompany == true && this.form.taxvatState != true) {
        await this.validateVAT();
        if (this.form.taxvatState != true) {
          formValid = false;
        }
      }
      Logger.debug("Form", "AccountEditAddress", this.form)();
      if (formValid == true) {
        const address = {};
        if (this.editAddressId > 0) {
          address.id = this.editAddressId;
        }
        address.company = this.form.company;
        address.country_code = this.form.address.country_code;
        address.firstname = this.form.firstname;
        address.lastname = this.form.lastname;
        address.street = this.form.address.streetDisplay;
        address.postcode = this.form.address.postcode;
        address.city = this.form.address.city;
        address.telephone = this.form.address.telephone;
        address.vat_id = this.form.taxvat;
        address.default_billing = this.form.address.default_billing;
        address.default_shipping = this.form.address.default_shipping;

        if (this.editAddressId > 0) {
          const retval = await this.$store.dispatch("user/updateAddress", {
            address: address,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account"),
              text: this.$t("address_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.editAddressId = 0;
          }
        } else if (this.editAddressId == -1) {
          const retval = await this.$store.dispatch("user/addAddress", {
            address: address,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account"),
              text: this.$t("address_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.editAddressId = 0;
          }
        }
      }
    },
    cancel() {
      this.editAddressId = 0;
    },
    async validateVAT() {
      Logger.debug("validateVAT", "VAT", this.form.taxvat.length)();
      if (this.isCompany == true) {
        if (this.form.taxvat.length > 0) {
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.taxvat);
            Logger.debug("vatcheck", "validateVat", retval.valid)();
            if (retval.valid == "true") {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        }
      } else {
        this.form.taxvatState = null;
      }
    },
    validateHouseNumber() {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        this.form.address.house_numberState = true;
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        Logger.debug(
          "this.form.company",
          "validateCompany",
          this.form.company
        )();
        if (this.form.company == "" || this.form.company == null) {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateCity() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState == true;
          this.foundAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
          //Logger.debug("PostcodeValidation", "length 0", list.length)();
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            Logger.debug("multiple addresses", "item", item)();
            this.foundAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    validatePostcode() {
      switch (this.form.address.country_code) {
        case "NL":
          if (this.form.address.postcode.match(/^\d{4}\s?[a-zA-Z]{2}$/)) {
            this.form.address.postcodeState = true;
          } else {
            this.form.address.postcodeState = false;
            this.form.address.postcodeError = this.$t("postcode_invalid_nl");
          }
          break;
        case "BE":
          if (this.form.address.postcode.match(/^\d{4}$/)) {
            this.form.address.postcodeState = true;
          } else {
            this.form.address.postcodeState = false;
            this.form.address.postcodeError = this.$t("postcode_invalid_be");
          }
          break;
        case "DE":
          if (this.form.address.postcode.match(/^\d{5}$/)) {
            this.form.address.postcodeState = true;
          } else {
            this.form.address.postcodeState = false;
            this.form.address.postcodeError = this.$t("postcode_invalid_de");
          }
          break;
        default:
          if (this.form.address.postcode == "") {
            this.form.address.postcodeState = false;
            this.form.address.postcodeError = this.$t("postcode_required");
          } else {
            this.form.address.postcodeState = true;
          }
      }
    },
  },
  watch: {
    country() {
      if (config.postcode_validation.enabled) {
        if (this.editAddressId <= -1) {
          if (!config.postcode_validation.countries.includes(this.country)) {
            this.showFullAddress = true;
          } else {
            this.showFullAddress = false;
          }
        }
      } else {
        this.showFullAddress = true;
      }
      if (this.form.taxvatState != true) {
        this.validateVAT();
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        } else {
          this.form.address.postcodeState = false;
          this.form.address.house_numberState = false;
        }
      }
    },
    selectedAddress(newVal) {
      Logger.debug("selectedAddress", "index", newVal)();
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";
.account-address-book {
  h1,
  h2 {
    font-weight: 300;
    letter-spacing: 0.012em;
    margin-bottom: 35px;

    @include media("screen", ">=tablet") {
      font-size: 38px;
    }
  }

  h2 {
    margin-bottom: 26px;
    border-bottom: 1px solid #000000;
    padding-bottom: 18px;
    @include media("screen", ">=tablet") {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: -0.015em;

    @include media("screen", ">=tablet") {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  address {
    span {
      display: block;
    }

    a {
      color: $light-blue;
      margin-left: 4px;
    }
  }

  .link {
    color: $light-blue;
    font-size: 17px;
    font-weight: 400;
  }
}

.address-block {
  padding-bottom: 28px;
}

.adjust {
  a {
    color: $light-blue;
  }
}

.remove {
  position: relative;
  margin-left: 8px;
  padding-left: 12px;

  &:before {
    content: "|";
    position: absolute;
    left: 0;
    top: 3px;
  }

  a {
    color: $red;
  }
}

.select-group {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.02em;

  @include media("screen", ">=tablet") {
    font-size: 20px;
  }

  span {
    padding-top: 4px;
    padding-left: 10px;

    @include media("screen", ">=tablet") {
      padding-top: 0;
    }
  }
}

.select-wrap {
  position: relative;
  margin-left: 10px;

  @include media("screen", ">=tablet") {
    margin-left: 32px;
  }

  &:before {
    content: "";
    background: $white;
    position: absolute;
    border-radius: 6px;
    top: 2px;
    bottom: 2px;
    right: 2px;
    width: 30px;
    z-index: 2;
  }

  &:after {
    content: "";
    border-top: 2px solid $black;
    border-left: 2px solid $black;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(-135deg);
    width: 13px;
    height: 13px;
    margin-top: -2px;
    z-index: 3;
  }
}

.select {
  position: relative;
  width: 100%;
  font-size: 16px;
  border-radius: 6px;
  border-color: $black;
  line-height: 1;
  height: 36px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 400;

  @include media("screen", ">=tablet") {
    font-size: 20px;
  }
}

.form-submit-btn {
  letter-spacing: 0.02em;

  @include media("screen", ">=tablet") {
    min-width: 230px;
  }
}
</style>
