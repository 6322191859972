<template>
  <div class="wishlist">
    Wensenlijst
    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4 mb-md-45"
        v-for="product of wishlistItems"
        :key="product.id"
      >
        <ProductCardWishlist :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import Cart from "@storefront/core/modules/cart/mixins";
import ProductCardWishlist from "@/esf_lommel_dt1_europe/core/components//ProductCardWishlist";
export default {
  name: "AccountWishlist",
  mixins: [Cart],
  components: { ProductCardWishlist },
  methods: {
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("my_wishlist"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
};
</script>
<style lang="scss" scoped>
.wishlist {
  font-weight: 300;
  margin-bottom: 35px;
  letter-spacing: 0.12rem;
  font-size: 38px;

  // @include media("screen", ">=tablet") {
  //   font-size: 25px;
  // }
}

.drop-heading {
  display: block;
  font-size: 16px;
  color: #000;
  margin-bottom: 14px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 14px);
    left: -14px;
    right: -14px;
    background: #f3f3f3;
    height: 1px;
  }
}

.drop-items {
  list-style: none;
  padding-left: 0;
  li {
    padding: 16px 25px 16px 14px;
    border-bottom: 1px solid #f3f3f3;
    position: relative;

    .lnr-cross {
      position: absolute;
      right: 3px;
      top: calc(50% + 2px);
      transform: translateY(-50%);
      font-size: 19px;
    }
  }
}

.drop-item--img {
  width: 46px;
  margin-right: 20px;

  img {
    width: 100%;
  }
}

.product-name {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 7px;
  align-items: center;
  display: flex;
}

.price {
  font-size: 16px;
  font-weight: 500;
}
</style>
