import { render, staticRenderFns } from "./AccountOrders.vue?vue&type=template&id=6dcd9144&scoped=true&"
import script from "./AccountOrders.vue?vue&type=script&lang=js&"
export * from "./AccountOrders.vue?vue&type=script&lang=js&"
import style0 from "./AccountOrders.vue?vue&type=style&index=0&id=6dcd9144&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dcd9144",
  null
  
)

export default component.exports