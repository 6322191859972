<template>
  <div class="account-my-account">
    <h1>{{ $t("my_account") }}</h1>
    <div class="address-block">
      <h2>{{ $t("account_information") }}</h2>
      <b-row>
        <b-col sm="6">
          <h3>{{ $t("contact_information") }}</h3>
          <address>
            <span>{{ user.firstname }} {{ user.lastname }}</span>
            <span>{{ user.email }}</span>
          </address>
          <b-link class="link" @click="goToEditAccount">{{ $t("edit") }}</b-link
          >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<b-link
            class="link"
            @click="goToEditPassword"
            >{{ $t("change_password") }}</b-link
          >
        </b-col>
        <b-col sm="6">
          <h3>{{ $t("newsletters") }}</h3>
          <b-link class="link" @click="goToEditSubscription">{{
            $t("modify_newsletters")
          }}</b-link>
        </b-col>
      </b-row>
    </div>
    <div class="address-block">
      <h2>
        {{ $t("address_book") }} &nbsp;&nbsp;&nbsp;<b-link
          class="link"
          @click="goToManageAddress"
          >{{ $t("manage_addresses") }}</b-link
        >
      </h2>
      <b-row>
        <b-col sm="6">
          <h3>{{ $t("default_billing_address") }}</h3>
          <div v-if="defaultBilling == null">
            {{ $t("no_default_billing_address") }}
          </div>
          <address v-if="defaultBilling != null">
            <span
              >{{ defaultBilling.firstname }}
              {{ defaultBilling.lastname }}</span
            >
            <span v-if="defaultBilling.company != null">{{
              defaultBilling.company
            }}</span>
            <span>{{ defaultBilling.street[0] }}</span>
            <span
              >{{ defaultBilling.city }}, {{ defaultBilling.postcode }}</span
            >
            <span>{{ defaultBilling.country_code }}</span>
            <span
              >T:<a :href="`tel:` + defaultBilling.telephone">
                {{ defaultBilling.telephone }}</a
              ></span
            >
            <span v-if="defaultBilling.vat_id != null"
              >VAT:{{ defaultBilling.vat_id }}</span
            >
          </address>
          <b-link
            class="link"
            @click="goToEditAddress(defaultBilling, 'billing')"
            >{{ $t("edit_address") }}</b-link
          >
        </b-col>
        <b-col sm="6">
          <h3>{{ $t("default_shipping_address") }}</h3>
          <div v-if="defaultShipping == null">
            {{ $t("no_default_shipping_address") }}
          </div>
          <address v-if="defaultShipping != null">
            <span
              >{{ defaultShipping.firstname }}
              {{ defaultShipping.lastname }}</span
            >
            <span v-if="defaultShipping.company != null">{{
              defaultShipping.company
            }}</span>
            <span>{{ defaultShipping.street[0] }}</span>
            <span
              >{{ defaultShipping.city }}, {{ defaultShipping.postcode }}</span
            >
            <span>{{ defaultShipping.country_code }}</span>
            <span
              >T:<a :href="`tel:` + defaultShipping.telephone">
                {{ defaultShipping.telephone }}</a
              ></span
            >
            <span v-if="defaultShipping.vat_id != null"
              >VAT:{{ defaultShipping.vat_id }}</span
            >
          </address>
          <b-link
            class="link"
            @click="goToEditAddress(defaultShipping, 'shipping')"
            >{{ $t("edit_address") }}</b-link
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountMyAccount",
  components: {},

  data() {
    return {};
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
  },
  methods: {
    goToEditAccount() {
      this.tabIndex = 4;
    },
    goToEditPassword() {
      this.tabIndex = 6;
    },
    goToEditAddress(address, type) {
      this.tabIndex = 3;
      if (address) {
        this.$store.commit("user/setEditAddressId", address.id);
      } else {
        this.$store.commit(
          "user/setEditAddressId",
          type == "billing" ? -2 : -3
        );
      }
    },
    goToManageAddress() {
      this.tabIndex = 3;
    },
    goToEditSubscription() {
      this.tabIndex = 8;
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("my_account"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.account-my-account {
  h1,
  h2 {
    font-weight: 300;
    letter-spacing: 0.012em;
    margin-bottom: 35px;

    @include media("screen", ">=tablet") {
      font-size: 38px;
    }
  }

  h2 {
    margin-bottom: 26px;
    border-bottom: 1px solid #000000;
    padding-bottom: 12px;
    @include media("screen", ">=tablet") {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: -0.015em;

    @include media("screen", ">=tablet") {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  address {
    span {
      display: block;
    }

    a {
      color: $light-blue;
      margin-left: 4px;
    }
  }

  .link {
    color: $light-blue;
    font-size: 17px;
    font-weight: 400;
  }
}

.address-block {
  padding-bottom: 28px;
}

.adjust {
  a {
    color: $light-blue;
  }
}

.remove {
  position: relative;
  margin-left: 8px;
  padding-left: 12px;

  &:before {
    content: "|";
    position: absolute;
    left: 0;
    top: 3px;
  }

  a {
    color: $red;
  }
}

.select-group {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.02em;

  @include media("screen", ">=tablet") {
    font-size: 20px;
  }

  span {
    padding-top: 4px;
    padding-left: 10px;

    @include media("screen", ">=tablet") {
      padding-top: 0;
    }
  }
}

.select-wrap {
  position: relative;
  margin-left: 10px;

  @include media("screen", ">=tablet") {
    margin-left: 32px;
  }

  &:before {
    content: "";
    background: $white;
    position: absolute;
    border-radius: 6px;
    top: 2px;
    bottom: 2px;
    right: 2px;
    width: 30px;
    z-index: 2;
  }

  &:after {
    content: "";
    border-top: 2px solid $gray-500;
    border-left: 2px solid $gray-500;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(-135deg);
    width: 13px;
    height: 13px;
    margin-top: -2px;
    z-index: 3;
  }
}

.select {
  position: relative;
  width: 72px;
  font-size: 16px;
  border-radius: 6px;
  border-color: $black;
  line-height: 1;
  height: 36px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 400;

  @include media("screen", ">=tablet") {
    font-size: 20px;
  }
}

.form-submit-btn {
  letter-spacing: 0.02em;

  @include media("screen", ">=tablet") {
    min-width: 230px;
  }
}
</style>
